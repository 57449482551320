import { defineStore } from 'pinia'
import { ref } from 'vue'
import { removeReference } from '@autobid/strapi-integration/utils/helpers'
import { makeSeparatedStore } from '@autobid/nuxt-pinia-store/utils/makeSeparatedStore'

export type StartDateRange = {
  dateFrom: string
  dateTo: string
}

export type AuctionFilterState = {
  type: string[]
  stage: string[]
  startDateRange?: StartDateRange
  appIds: number[]
  descriptionTitles: string[]
  countryIds: string[]
  designations: string[]
  numbers: number[]
}

const INITIAL_AUCTION_FILTER_STATE: AuctionFilterState = {
  type: [],
  stage: [],
  startDateRange: { dateFrom: '', dateTo: '' },
  appIds: [],
  countryIds: [],
  designations: [],
  numbers: [],
  descriptionTitles: []
}

export const useAuctionStore = makeSeparatedStore((key: string) =>
  defineStore(`auction/${key ?? ''}`, () => {
    const filter = ref(removeReference(INITIAL_AUCTION_FILTER_STATE))
    const isOpen = ref(false)
    const isInitRender = ref(true)

    const reset = () => {
      filter.value = removeReference(INITIAL_AUCTION_FILTER_STATE)
    }

    return {
      isInitRender,
      filter,
      isOpen,
      reset
    }
  })
)

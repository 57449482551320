/**
 * Higher order function to create multiple instances of the same store
 *
 * @example const useCarStore = makeSeparateStore((id: string) => defineStore(`car/${id}`, config))
 */
export function makeSeparatedStore<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (storeKey: string) => any,
  K extends T extends (storeKey: string) => infer StoreDef ? StoreDef : never
>(defineStore: T) {
  const definedStores = new Map<string, K>()

  return (storeKey: string): ReturnType<K> => {
    if (!definedStores.has(storeKey)) {
      definedStores.set(storeKey, defineStore(storeKey))
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return definedStores.get(storeKey)()
  }
}
